.line{
	background-image: url(../img/bg2.jpg);
	background-position: center;
	background-attachment: fixed;
	background-size: cover;
	padding: (33/@bfz)*1em 0;
	&_p{
		font-size: (25/@bfz)*1em;
		color: @white;
		.ebold;
		text-transform: uppercase;
		letter-spacing: 0.03em;
		margin: 0;
		text-shadow: 0px (20/@bfz)*1em (30/@bfz)*1em  fade(@tshad,40%);
	}

}

@media @phone-max{
	.line{
		text-align: center;
		&_p{
			font-size: 18px;
			
			margin-bottom: 0;	
		}
		.btn{margin-top: 10px;	}
	}
}