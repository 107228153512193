.header{
	background: @white;
	padding: (16/@bfz)*1em 0;
	&_link{
		.ebold;
		@tfz:20;
		.proxi;
		.ebold;
		font-size:(@tfz/@bfz)*1em ;
		color: @tcolor;
		vertical-align: middle;
		i{
			color: @orange;
			@ifz:18;
			font-size: (@tfz/@bfz)*1em;
			vertical-align: middle;
		}
	}
}