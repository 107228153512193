.footer{
	background: rgb(47,83,139);
	background: -moz-linear-gradient(top, rgba(47,83,139,1) 0%, rgba(25,52,94,1) 100%);
	background: -webkit-linear-gradient(top, rgba(47,83,139,1) 0%,rgba(25,52,94,1) 100%);
	background: linear-gradient(to bottom, rgba(47,83,139,1) 0%,rgba(25,52,94,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2f538b', endColorstr='#19345e',GradientType=0 );
	padding-top: (40/@bfz)*1em;
	text-align: center;
	.row{
		margin-top: (20/@bfz)*1em;
		margin-bottom: (20/@bfz)*1em;
	}
	&_link,&_p-large{
		@tfz:20;
		font-size: (@tfz/@bfz)*1em;
		color: @white;
		.ebold;
		i{
			color: @yellow;
		}
	}
	&_p{
		color: @white;
		@tfz:18;
		font-size:(@tfz/@bfz)*1em ;
		line-height: 1;	
		&-large{
			text-transform: uppercase;
			position: relative;
			span{
				
				&:after
				{
					content: attr(title);
					position: absolute;
					left: -100%;
					right: -100%;
					top: -100%;
					bottom: -100%;
					margin: auto;
					line-height: 1.7;
					opacity: .1;
					@sfz:51;
					font-size: (@sfz/20)*1em;
					content: attr(title);
					height: (77/@sfz)*1em
				}
			}
		}
	}
	&_copy{
		@tfz:13;
		font-size: (@tfz/@bfz)*1em;
		color: @white;
		padding-top: (31/@tfz)*1em;
		padding-bottom: 0;
		position: relative;
		z-index: 2;
		@mrg:10;
		margin-top: (@mrg/@tfz)*1em;
		margin-bottom: -(@mrg/@tfz)*1em;	
	}
	.btn{
		display: 	inline-block;
		width: auto;
		padding-left: (20/@bfz)*1em;
		padding-right: 	(20/@bfz)*1em;
	}
	&_decor{
		position: absolute;
		top: 0;	
		bottom: 0;	
		right: 0;	
		margin-left: -100%;
		z-index: 1;
		&-right{
			margin-left: 0;	
			margin-right: -100%;
			right: 	inherit;
			left: 0;
		}
	}
}

@media @phone-max{
	.footer{
		&_decor{
			display: 	none;
		}
		&_copy{
			margin: 20px 0;	
			padding: 0;	
		}
	}
}