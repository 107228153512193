.bblock{
	background: url(../img/bg3.jpg);
	background-size: cover;
	background-repeat: no-repeat;
	position: relative;

	&_img{
		/* margin-left: -(28/@bfz)*1em;
		margin-right: -(45/@bfz)*1em; */
		margin-left: -(230/@bfz)*1em;
		margin-right: -(85/@bfz)*1em;
		position: relative;
		z-index: 2;
		padding: (219/@bfz)*1em 0;

		img{
			width: 100%;
		}
	}
	.container,.row,[class^='col']{
		height: 100%;
	}
	&_shape{
		background: fade(@blue,80%);
		.ACenter;
		z-index: 1;
	}
	&_h1{
		text-align: center;
		@tfz:44;
		font-size: (@tfz/@bfz)*1em;
		color: @white;
		.ebold;
		.proxi;
		position: relative;
		z-index: 2;
		text-transform: uppercase;
		text-shadow: 0px (20/@tfz)*1em (30/@tfz)*1em fade(@prewhite,40%);
		&-title{
			position: relative;
			&:before{
				content: attr(title);
				position: absolute;
				left: -100%;
				right: -100%;
				top: -100%;
				bottom: -100%;
				margin: auto;
				line-height: 2;
				opacity: .1;

				@ifz:36+@tfz;
				font-size: (@ifz/@tfz)*1em;
				
			}
			
		}
		&:after{
			display: block;
			width: (164/@tfz)*1em;
			margin: (20/@tfz)*1em auto;
			height: 2px;
			background: @yellow;
			opacity: .3;
			content: '';
		}


		
	}
	
	&_text{
		@tfz:18;
		font-size: (@tfz/@bfz)*1em;
		color: @white;
		text-indent: (36/@bfz)*1em;

	}
	&_decor{
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: auto;
		margin-top: -26%;
		margin-left: -50%;
		margin-right: -20%;
		z-index: -1;
		img{
			width: 100%;
		}
	}
	&_icon{
		position: absolute;
		left: 0;
		right: 0;
		bottom: (30/@bfz)*1em;
		transform: rotate(90deg);
		width: (20/@bfz)*1em;
		height: (20/@bfz)*1em;
		margin: auto;
		color: @white;
		text-align: center;
	}
	.posr{
		height: 100%;
		z-index: 2;
	}
	&_btn{
		@tfz:24;
		font-size: (@tfz/@bfz)*1em;
		margin-top: (50/@tfz)*1em;

	}
}

@media @phone-max{
	.bblock{
		padding: 50px 0;
		&_decor{
			display: 	none;
		}
	}
}