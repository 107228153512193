.wywe{
	
	&_icon{
		text-align: center;
		font-size: (100/@bfz)*1em;
		width: 100%;
		i{
			width: 100%;
			text-align: center;
			display: block;
			color: @blue;
			&:before{
				display: block;
				width: 100%;
			}
		}
	}
	&_el{
		text-align: center;
	}
	&_name{
		font-size: (16/@bfz)*1em;
		.ebold;
		color: @blue;
		text-transform: uppercase;
		letter-spacing: 0.04em;


	}
	&_loop{
		margin-top: (10/@bfz)*1em;
		margin-bottom: (10/@bfz)*1em;
	}
	&_p{
		font-size: (14/@bfz)*1em;
		color: @tcolor;

	}
}