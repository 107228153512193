/* .call-label;
*/
@bfz:14;

@dfz:12;
@tabfz:12;
@pfz:12;
body{
	.normal;
	.proxi;
}


*{
	outline: none!important;
}
a{
	&:hover{
		text-decoration: none;
	}
}
img{
	max-width: 100%;
	width: auto;
	&.samp{
		width: 100%;
		opacity: 0;
	}

}
.marimg{
	margin-left: -51%;	
	margin-bottom: -13%;
}
.icon{
	&-arrow{
		&-left, &-right{
			&:before{width: auto;}
		}
	}
}
[class^='col'],.container{
	padding-left: (15/@bfz)*1em;
	padding-right:(15/@bfz)*1em;
}

.test{
	background:#c00;
	box-shadow: inset 0 0 0  1px #000;
	min-width: (10/@bfz)*1em;
	min-height: (10/@bfz)*1em;
	width: 100%;
	height: 100%;
	/* color: #000; */
	color: #fff;
	.bold;
	text-align: center;
}
.row{
	margin-left: (-15/@bfz)*1em;
	margin-right:(-15/@bfz)*1em;
	&.va-middle{
		padding-left: (4/@bfz)*1em;
		width: 102.6%;
		[class^='col']{
			float:  none;
			display:  inline-block;
			margin-left: (-4/@bfz)*1em;
			vertical-align: middle;
		}
	}
}
.va-middle{
	display: 	inline-block;
	vertical-align: middle;
	height: 100%;
	width: 	100%;
	&:before{
		width: (0/@bfz)*1em;
		content: '';

		vertical-align: middle;
		height: 100%;
		display: 	inline-block;
	}
	.middler{
		display: inline-block;
		width: 100%;
		vertical-align: middle;
		margin-left: (-5/@bfz)*1em;
		/* margin-left: (-3/@bfz)*1em; */

	}
}
.btn{
	width: 100%;
	color: @white;
	box-shadow: 0 (10/@bfz)*1em (20/@bfz)*1em 0  rgba(255, 154, 0, 0.3);
	background: rgb(255,181,69);
	background: -moz-linear-gradient(top, rgba(255,181,69,1) 0%, rgba(255,154,0,1) 100%);
	background: -webkit-linear-gradient(top, rgba(255,181,69,1) 0%,rgba(255,154,0,1) 100%);
	background: linear-gradient(to bottom, rgba(255,181,69,1) 0%,rgba(255,154,0,1) 100%);
	/* filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffb545', endColorstr='#ff9a00',GradientType=0 ); */
	@tfz:18;
	font-size: (@tfz/@bfz)*1em;
	border-radius: (30/@tfz)*1em;
	text-transform: uppercase;
	@color: @white;
	letter-spacing: 0.04em;
	.bold;
	&:hover{
		color: @white;
		background: rgb(255,154,0);
		background: -moz-linear-gradient(top, rgba(255,154,0,1) 0%, rgba(255,181,69,1) 100%);
		background: -webkit-linear-gradient(top, rgba(255,154,0,1) 0%,rgba(255,181,69,1) 100%);
		background: linear-gradient(to bottom, rgba(255,154,0,1) 0%,rgba(255,181,69,1) 100%);
		/*filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff9a00', endColorstr='#ffb545',GradientType=0 ); */



	}
	&-wa{
		width: auto;
	}
}
h1,.h1{
	@tfz:40;
	font-size:(@tfz/@bfz)*1em;
	.ebold;
	color: @tcolor;
	text-align: center;
	text-transform: uppercase;
	position: relative;
	margin: 0;
	.proxi;
	span:after{
		content: attr(title);
		position: absolute;
		left: -100%;
		right: -100%;
		top: -100%;
		bottom: -100%;
		margin: auto;
		line-height: 2.2;
		opacity: .1;
		@ifz:80;
		font-size: (@ifz/@tfz)*1em;
	}
	&:after{
		content: '';
		display: block;
		margin: (20/@tfz)*1em auto;
		width: (68/@tfz)*1em;
		height: (2/@bfz)*1em;
		background: @blue;
	}
}
section{
	padding: (65/@bfz)*1em 0;

}
.poss{position: static;}
.posr{position: relative;}
.posa{position: absolute;}
.posf{position: fixed;}

.btnholder{
	max-width: 100%;
}
p{
	&.tind{
		@tfz:18;
		font-size: (@tfz/@bfz)*1em;
		color: @tcolor;
		letter-spacing: 0.04em;
		text-indent: (37/@bfz)*1em;

	}
}

@media @desktop-max{
	body{font-size: @dfz*1px;}
}
@media @tablet-max{
	body{font-size: @tabfz*1px;}
	h1,.h1{
		span{
			&:after{
				@tfz:68;
				.ACenter();
				height: (170/@tfz)*1em;
			}
		}
	}
}
@media @phone-max{
	body{font-size: @pfz*1px;}
	h1,.h1{
		span{
			&:after{
				font-size: 1.5em;
			}
		}
	}
	.row.va-middle{
		display: 	block;	
		width: auto;
	}
	.btn{
		max-width: 320px;
		margin: 0 auto;	
		display: 	block;	
	}
	.marimg{
		margin-left: 0;
		margin-bottom: 0;
	}
	img{
		max-width: 320px;
		display: 	block;	
		margin: 20px auto;	
	}
	.tind{
		
	}
}