.lastby{
	&_el{
		width: (263/@bfz)*1em;
		position: relative;
		display: inline-block;
		border: (1/@bfz)*1em solid @bordcolor;
		height: (400/@bfz)*1em;
		text-align: left;
	}
	.samp{
		width: 100%;
		opacity: 0;
	}
	&_poster{
		position: relative;
		border-bottom: (1/@bfz)*1em solid @bordcolor;
		img{
			width: 100%;
			max-width: 100%;
			margin: 0;	
		}
	}
	&_img{
		.ACenter;
	}
	&_text{
		padding: (9/@bfz)*1em (13/@bfz)*1em;
		position: relative;
		z-index: 2;
	}
	&_name{
		@tfz:18;
		font-size: (@tfz/@bfz)*1em;
		.ebold;
		color: @tcolor;
		letter-spacing: 0.04em;
		margin-bottom: (6/@bfz)*1em;
	}
	&_discr{
		@tfz:14;
		font-size: (@tfz/@bfz)*1em;
		letter-spacing: 0.04em;
		height: (40/@tfz)*1em;
	}
	&_price{
		color: @tcolor;
		@tfz:18;
		font-size:(@tfz/@bfz)*1em ;
		.ebold;
		span{
			color: @yellow;
		}
		i{
			.seg;
			font-style: normal;
		}
	}
	&_mark{
		&-holder{
			position: absolute;
			top: 0;	
			bottom: 0;	
			right: 0;	
			width: 	50%;
			opacity: .1;
			z-index: -1;
		}
		.ACenter();
	}
	&_slide{
		margin-top: (20/@bfz)*1em;	
		text-align: center;
	}

	&_arw{
		.ACenter;
	}
	.slick{
		&-slider{
			z-index: 3;
		}
		&-arrow{
			position: absolute;
			top: 0;	
			bottom: 0;
			@tfz:61;
			font-size:(@tfz/@bfz)*1em ;
			background: transparent;
			border: none;
			color: @blue;
			width: 60px;
			height: 89px;
			text-align: center;
			line-height: 1;
			margin: auto;	
			z-index: 3;
			/* box-shadow: 0px 0px (33.25/@tfz)*1em (1.75/@tfz)*1em fade(@blue,40%); */
			
			//i{text-shadow: 0px 0px (33.25/@tfz)*1em fade(@blue,40%); }
			transition: color .3s, box-shadow .3s, opacity .3s;
			&:after{
				.ACenter;
				width: 1px;	
				height: 1px;	
				border-radius: 50%;
				content: '';
				box-shadow: 0px 0px  1em 0.1em   fade(@blue,40%);
			}
			&:hover{
				color: @tcolor;
				opacity: .3;
				&:after{
					box-shadow: 0px 0px  0 0   fade(@blue,40%);
				}
			}
		}
		&-next{
			right: 	0;
		}
		&-prev{
			left: 0;	
		}
		&-dots{
			display: 	block;	
			width: 	100%;
			text-align: center;
			padding: (20/@bfz)*1em 0;	
			margin: 0;	
			
			li{
				display: 	inline-block;
				vertical-align: middle;
				background: @blue;
				width: (8/@bfz)*1em;	
				height: (8/@bfz)*1em;	
				border-radius: 50%;
				margin: 0 (4/@bfz)*1em;
				opacity: .2;
				transition: opacity .3s; 
				cursor: pointer;
				&.slick-active,&:hover{
					opacity: 1;
				}
			}
		}
	}

}