.by{
	&_el{
		text-align: center;
		margin: 105px 0px;
		&:first-child{
			margin-top: 0px;
		}
		&:last-child{
			margin-bottom: 0;
		}

	}
	&_el:first-child &_icon-i&_icon{
		left: -(90/@bfz)*1em;
		text-align: left;
		&-right{
			left: inherit;
			right: -(90/@bfz)*1em;
			text-align: right;
		}
	}
	&_el:nth-child(2) &_icon-i&_icon, &_el:nth-child(3) &_icon-i&_icon{

		text-align: right;

		&-right{
			text-align: left;
		}
		
	}


	&_name{
		font-size: (16/@bfz)*1em;
		color: @blue;
		.ebold;
		text-transform: uppercase;
		letter-spacing: 0.04em;
		text-align: center;
		background: @white;
		box-shadow: 0px 0px 10px @white;
		span{
			background: @white;
			position: relative;
			z-index: 3;
			display: inline-block;
			padding-left: (15/@bfz)*1em;
			padding-right: (15/@bfz)*1em;
		}
	}
	&_icon{
		width: (48/@bfz)*1em;
		color: @blue;

		position: absolute;
		top: 0;
		bottom: 0;
		margin: auto;
		left: -(86/@bfz)*1em;
		line-height: (38/@bfz)*1em;
		height: (38/@bfz)*1em;
		&-right{
			left: inherit;
			right:-(86/@bfz)*1em; 
		}
		img,i{

		}

	}
	&_loop{
		padding-top: (36/@bfz)*1em;
	}
	&_text{
		font-size: (14/@bfz)*1em;
		color: @tcolor;
		line-height: 1;
	}
	&_top{
		position: relative;
		&:before{
			display: inline-block;
			content: '';
			height: 1px;
			border-bottom:dotted (3/@bfz)*1em @blue;
			
			.ACenter(@left: -((86-48)/@bfz)*1em,@right: -((86-48)/@bfz)*1em);


		}
	}

	&_black{
		color: @blue;
		.black;
		font-size: (20/@bfz)*1em;
		margin-bottom: 0;
		text-align: center;
		line-height: 1.2;
		letter-spacing: 0.04em;
		span{color:@yellow;}
	}
	&_tbl{
		margin-top: (30/@bfz)*1em;
	}
	[class^='col']{
		position: relative;
		z-index: 2;
	}
	[class^='col']&_decor{
		z-index: 1;
	}
	&_tbl{
		position: relative;
		

		display: inline-block;
		background: @white;
		padding: (16/@bfz)*1em (26/@bfz)*1em;
		box-shadow: 0px (20/@bfz)*1em (56/@bfz)*1em (14/@bfz)*1em fade(@blue,10%);
		&:before,&:after{
			content: 	'';
			position: absolute;
			top: 0;	
			right: 0;
			



		}
		&:before{
			width: 	100%;
			height: (2/@bfz)*1em;
			background: rgb(255,255,255);
			background: -moz-linear-gradient(left, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 34%, rgba(255,154,0,1) 100%);
			background: -webkit-linear-gradient(left, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 34%,rgba(255,154,0,1) 100%);
			background: linear-gradient(to right, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 34%,rgba(255,154,0,1) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ff9a00',GradientType=1 );

		}
		&:after{
			height: 100%;
			width: (2/@bfz)*1em;
			background: rgb(255,154,0);
			background: -moz-linear-gradient(top, rgba(255,154,0,1) 0%, rgba(255,255,255,1) 66%, rgba(255,255,255,1) 100%);
			background: -webkit-linear-gradient(top, rgba(255,154,0,1) 0%,rgba(255,255,255,1) 66%,rgba(255,255,255,1) 100%);
			background: linear-gradient(to bottom, rgba(255,154,0,1) 0%,rgba(255,255,255,1) 66%,rgba(255,255,255,1) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff9a00', endColorstr='#ffffff',GradientType=0 );
		}
		ul{
			display: 	inline-block;
			width: 50%;
			vertical-align: middle;
			padding: 0;	
			margin: 0;	
			margin-left: -(4/@bfz)*1em;
			padding-left: (4/@bfz)*1em;
			line-height: 0;
			margin-top: -(6/@bfz)*1em;	
			li{
				text-align: center;
				display: 	inline-block;
				width: 100%/3;
				margin-left: -(4/@bfz)*1em;	
				&:after{
					display: 	inline-block;
					content: 	'';
					background: @yellow;
					width: (3/@bfz)*1em;
					height: (3/@bfz)*1em;
					border-radius: 	50%;
				}
				&:first-child{
					text-align: left;
				}
				&:last-child{
					text-align: right;
				}
			}
		}
		@m:29;
		&-cercle{
			width: (10/@bfz)*1em;
			height: (10/@bfz)*1em;
			display: 	block;	
			background: @yellow;
			border-radius: 	50%;
			vertical-align: middle;
			display: 	inline-block;
			margin-top: -(3/@bfz)*1em;	
		}
		&-arrw{
			display: 	inline-block;
			width: (100/@bfz)*1em;
			position: absolute;
			left: 100%;
			bottom: 100%;
			transform: rotate(-45deg);
			transform-origin: center left;
			line-height: 1;
			margin-bottom: -(11/@bfz)*1em;
			margin-left: -(4/@bfz)*1em;
		}
		i{
			width: auto;
			color: @yellow;
			display: 	inline-block;
			width: 50%;
			
			margin-left: -(14/@bfz)*1em;
			padding-left: 	(14/@bfz)*1em;
			&:before{
				display: 	inline-block;
				/* margin-right: 50%; */
			}
		}
		&-right &{

			&-arrw{
				left: inherit;
				right: 	100%;
				transform: rotate(45deg);
				transform-origin: center right;
				margin-left: 0;	
				margin-right: -(4/@bfz)*1em;
			}
		}
		&-right{
			&:before{
				background: rgb(255,154,0);
				background: -moz-linear-gradient(left, rgba(255,154,0,1) 0%, rgba(255,255,255,1) 66%, rgba(255,255,255,1) 100%);
				background: -webkit-linear-gradient(left, rgba(255,154,0,1) 0%,rgba(255,255,255,1) 66%,rgba(255,255,255,1) 100%);
				background: linear-gradient(to right, rgba(255,154,0,1) 0%,rgba(255,255,255,1) 66%,rgba(255,255,255,1) 100%);
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff9a00', endColorstr='#ffffff',GradientType=1 );
			}
			i{
				margin-right: -(14/@bfz)*1em;
				padding-right: 	(14/@bfz)*1em;
				margin-left: 0;	
				padding-left: 0;
				&:before{
					display: 	inline-block;
					margin-right: 0;	
					/* margin-left: 50%; */
				}

			}
			&:after{
				right: inherit;
				left: 0;
			}
		}
	}
	
}

@media @tablet-max{
	@bfz:@tabfz;
	.by{
		&_icon{
			left: 	0;
			text-align: center;
			&-right{
				left: inherit;
				right: 	0;
			}
		}
		&_el{
			margin:(10/@bfz)*1em;
			font-size: 14px;

		}
		&_el:nth-child(2) &_icon-i&_icon, &_el:nth-child(3) &_icon-i&_icon{
			text-align: center;
			&-right{
				text-align: center;
			}

		}
		&_el:first-child &_icon-i&_icon{
			left: 0;
			text-align: center;
			&-right{
				left:inherit;
				right: 	0;
				text-align: center;
			}
		}
		&_name{
			margin:(10/@bfz)*1em 0;
		}

		&_top{
			&:before{
				left: 	(40/@bfz)*1em;
				right: 	(40/@bfz)*1em;
			}
		}
		&_tb-ta{
			text-align: center;
		}
		&_tbl{
			&-arrw{
				width: auto;
			}
			i,ul{
				display: 	none;
			}
			
		}
	}
}

@media @phone-max{
	.by{
		text-align: center;
		&_el{
			max-width: 320px;
			margin:20px auto;
			display: block;
		}
		&_icon{
			img{
				margin: 0;	
				width: 	100%;
			}
		}
	}
}