@Black:900;
@Bold:700;
@ExtraBold:800;
@ExtraLight:200;
@Light:300;
@Medium:500;
@Normal:400;
@SemiBold:600;
@Thin:100;
@bold:@Bold;
@black:@Black;
@light:@Light;
@semibold:@SemiBold;
@semilight:@ExtraLight;
//.font(@fontname:'Muller',@fontpath:"../font/Muller" , @fontpref:"Black",@fweight:900,@fital:normal);

.Black,.black{font-weight: @Black }
.Bold,.bold{font-weight: @Bold }
.ExtraBold,.ebold{font-weight: @ExtraBold }
.ExtraLight,.elight,.semilight{font-weight: @ExtraLight }
.Light,.light{font-weight: @Light }
.Medium,.med{font-weight: @Medium }
.Normal,.normal{font-weight: @Normal }
.SemiBold,.sbold,.semibold{font-weight: @SemiBold }
.Thin,.thin{font-weight: @Thin }
.italic{font-style: italic;}

.font(@fontname:'ProximaNova',@fontpath:"../font/ProximaNova" , @fontpref:"-RegularIt",@fweight:@Normal,@fital:italic);
.font(@fontname:'ProximaNova',@fontpath:"../font/ProximaNova" , @fontpref:"-Regular",@fweight:@Normal,@fital:normal);
.font(@fontname:'ProximaNova',@fontpath:"../font/ProximaNova" , @fontpref:"-LightIt",@fweight:@Light,@fital:italic);
.font(@fontname:'ProximaNova',@fontpath:"../font/ProximaNova" , @fontpref:"-Light",@fweight:@Light,@fital:normal);
.font(@fontname:'ProximaNova',@fontpath:"../font/ProximaNova" , @fontpref:"-Extrabld",@fweight:@ExtraBold,@fital:normal);
.font(@fontname:'ProximaNova',@fontpath:"../font/ProximaNova" , @fontpref:"-BoldIt",@fweight:@Bold,@fital:italic);
.font(@fontname:'ProximaNova',@fontpath:"../font/ProximaNova" , @fontpref:"-Bold",@fweight:@Bold,@fital:normal);
.font(@fontname:'ProximaNova',@fontpath:"../font/ProximaNova" , @fontpref:"-Black",@fweight:@Black,@fital:normal);
.proxi{
	font-family: 'ProximaNova';
}

.seg{
	font-family: "segoeui";
}
.font(@fontname:'segoeui',@fontpath:"../font/segoeui" , @fontpref:"",@fweight:@Normal,@fital:normal);
.font(@fontname:'segoeui',@fontpath:"../font/segoeui" , @fontpref:"-black",@fweight:@black,@fital:normal);
.font(@fontname:'segoeui',@fontpath:"../font/segoeui" , @fontpref:"-blackitalic",@fweight:@black,@fital:Italic);
.font(@fontname:'segoeui',@fontpath:"../font/segoeui" , @fontpref:"-bold",@fweight:@bold,@fital:normal);
.font(@fontname:'segoeui',@fontpath:"../font/segoeui" , @fontpref:"-bolditalic",@fweight:@bold,@fital:Italic);
.font(@fontname:'segoeui',@fontpath:"../font/segoeui" , @fontpref:"-italic",@fweight:@Normal,@fital:Italic);
.font(@fontname:'segoeui',@fontpath:"../font/segoeui" , @fontpref:"-light",@fweight:@light,@fital:normal);
.font(@fontname:'segoeui',@fontpath:"../font/segoeui" , @fontpref:"-lightitalic",@fweight:@light,@fital:Italic);
.font(@fontname:'segoeui',@fontpath:"../font/segoeui" , @fontpref:"-semibold",@fweight:@semibold,@fital:normal);
.font(@fontname:'segoeui',@fontpath:"../font/segoeui" , @fontpref:"-semibolditalic",@fweight:@semibold,@fital:Italic);
.font(@fontname:'segoeui',@fontpath:"../font/segoeui" , @fontpref:"-semilight",@fweight:@semilight,@fital:normal);
.font(@fontname:'segoeui',@fontpath:"../font/segoeui" , @fontpref:"-semilightitalic",@fweight:@semilight,@fital:Italic);