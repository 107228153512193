.step{
	background: @dcolor;
	&_loop{
		padding-top: (50/@bfz)*1em;
	}
	&_cont{
		display: inline-block;
		vertical-align: middle;
		width: 20%;
		margin-left: -(4/@bfz)*1em;
		padding-left: (4/@bfz)*1em;
		text-align: center;
		vertical-align: top;
	}
	&_el{
		padding: 0 (8/@bfz)*1em;
		text-align: center;
		position: relative;

	}
	&_icon{
		width: 	(64/@bfz)*1em;
		height: (61/@bfz)*1em;
		position: relative;
		display: 	inline-block;
		img{
			.ACenter();
		}
	}
	&_top{
		position: relative;

	}
	&_name{
		@tfz:18;
		font-size:(@tfz/@bfz)*1em ;
		color: @blue;
		letter-spacing: 0.04em;
		.ebold;
		text-transform: uppercase;
		margin: (15/@tfz)*1em 0;
		margin-bottom: (7/@tfz)*1em;	
	}
	&_text{
		@tfz:14;
		font-size:(@tfz/@bfz)*1em ;
		line-height: 1.25;
		position: relative;

	}
	&_dec{
		position: absolute;
		top: 0;
		left: 	0;
		bottom: 0;
		right: 	100%;
		.black;
		@tfz:72;
		font-size: (@tfz/@bfz)*1em;
		height: (72/@tfz)*1em;
		color: @tcolor;
		opacity: .1;
		margin: auto;	
	}
	&_arw{
		position: absolute;
		top: 0;	
		bottom: 0;	
		left: 100%;
		z-index: 3;
		height: (20/@bfz)*1em;
		margin: auto;
		width: (70/@bfz)*1em;
		margin-left: -((70/2)/@bfz)*1em;
		ul{
			display: 	inline-block;
			width: 50%;
			vertical-align: middle;
			padding: 0;	
			margin: 0;	
			margin-left: -(4/@bfz)*1em;
			padding-left: (4/@bfz)*1em;
			line-height: 0;
			margin-top: -(4/@bfz)*1em;	
			li{
				text-align: center;
				display: 	inline-block;
				width: 50%;
				margin-left: -(4/@bfz)*1em;	
				&:after{
					display: 	inline-block;
					content: 	'';
					background: @blue;
					width: (3/@bfz)*1em;
					height: (3/@bfz)*1em;
					border-radius: 	50%;
				}

			}
		}
		i{
			width: auto;
			color: @blue;
			display: 	inline-block;
			width: 50%;

			margin-left: -(10/@bfz)*1em;
			padding-left: 	(10/@bfz)*1em;
			&:before{
				display: 	inline-block;
				/* margin-right: 50%; */
			}
		}

	}
	&_arww{
		display: 	inline-block;
		width: (300/@bfz)*1em;
		position: absolute;
		left: 100%;
		bottom: 100%;
		transform: rotate(-45deg);
		transform-origin: center left;
		line-height: 1;
		margin-bottom: -(11/@bfz)*1em;
		margin-left: -(4/@bfz)*1em;

	}
}

@media @phone-max{
	.step{
		&_arw{
			display: 	none;
		}
		&_cont{
			display: 	block;	
			width: 	100%;
		}
		&_el{
			display: block;
			width: 	300px;	
			margin: 20px auto;
		}
		&_text{
			height: auto!important;
		}
	}
}