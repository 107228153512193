
.icon-av_3:before { content: '\e800'; } /* '' */
.icon-av_4:before { content: '\e801'; } /* '' */
.icon-arrow-right:before { content: '\e802'; } /* '' */
.icon-av_1:before { content: '\e803'; } /* '' */
.icon-av_2:before { content: '\e804'; } /* '' */
.icon-phone:before { content: '\e805'; } /* '' */
.icon-arrow-left:before { content: '\e806'; } /* '' */
.icon-right-open:before { content: '\e807'; } /* '' */
.icon-left-open:before { content: '\e808'; } /* '' */
.icon-av_8:before { content: '\e809'; } /* '' */
.icon-av_5:before { content: '\e80a'; } /* '' */
.icon-av_7:before { content: '\e80c'; } /* '' */
.icon-av_6:before { content: '\e900'; } /* '' */
.icon-copyright:before { content: '\f1f9'; } /* '' */