.top{
	background: url(../img/bg1.jpg);
	background-size: cover;
	background-repeat: no-repeat;
	position: relative;
	&_img{
		/* margin-left: -(28/@bfz)*1em;
		margin-right: -(45/@bfz)*1em; */
		margin-left: -(20/@bfz)*1em;
		margin-right: -(20/@bfz)*1em;
		position: relative;
		z-index: 2;
		img{
			width: 100%;
		}
	}
	.container,.row,[class^='col']{
		height: 100%;
	}
	&_shape{
		background: fade(@blue,80%);
		.ACenter;
		z-index: 1;
	}
	&_h1{
		text-align: center;
		@tfz:64;
		font-size: (@tfz/@bfz)*1em;
		color: @white;
		.ebold;
		.proxi;
		position: relative;
		z-index: 2;
		text-transform: uppercase;
		/* text-shadow: 0px 45px 75px rgba(24, 33, 40, 0.4);
		*/
		&-title{
			position: relative;
			&:before{
				content: attr(title);
				position: absolute;
				left: -100%;
				right: -100%;
				top: -100%;
				bottom: -100%;
				margin: auto;
				line-height: 2.3;
				opacity: .1;

				@ifz:36+@tfz;
				font-size: (@ifz/@tfz)*1em;
				
			}
			
		}


		text-shadow: 0px (20/@tfz)*1em (30/@tfz)*1em fade(@prewhite,40%);
		
	}
	&_author{
		text-align: right;
		@tfz:20;
		margin-top: (66/@tfz)*1em;
		font-size: (@tfz/@bfz)*1em;
		color: @white;
		.italic;
		text-shadow: 0px (20/@tfz)*1em (30/@tfz)*1em rgba(24, 33, 40, 0.4);
	}
	&_decor{
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: auto;
		margin-top: -26%;
		margin-left: -20%;
		margin-right: -20%;
		img{
			width: 100%;
		}
	}
	&_icon{
		position: absolute;
		left: 0;
		right: 0;
		bottom: (30/@bfz)*1em;
		transform: rotate(90deg);
		width: (20/@bfz)*1em;
		height: (20/@bfz)*1em;
		margin: auto;
		color: @white;
		text-align: center;
	}
}

@media @tablet-max{
	.top{
		&_h1{
			font-size: (40/@bfz)*1em;
		}
	}
}

@media @phone-max{
	.top{
		&_icon,&_decor{
			display: none;
		}

	}
}